import React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MarkLatex from "../../atom/Marklatex/MarkLatex";
import { usePersistedStore } from "../../../hooks";
import { formatText } from "../../../tools";

const QuestionStatementContainer = styled("div")(
  ({ theme, isoption, ismq, ismqreview, answeropen }) => ({
    color: theme.palette.appcolors.black,
    height:
      !isoption &&
      ismq &&
      `calc(${answeropen ? "100% + 230px" : "100vh"} - ${
        ismqreview ? "230px" : "190px"
      })`,
    overflow: !isoption && ismq && "auto",
    scrollbarWidth: !isoption && ismq && "thin",
    scrollbarColor:
      !isoption && ismq && `${theme.palette.appcolors.grey4} transparent`,
    padding: !isoption && ismq && "1rem 8px 2rem 8px",
    [theme.breakpoints.up(1200)]: {
      "&:hover": {
        scrollbarColor:
          !isoption && ismq && `${theme.palette.appcolors.grey3} transparent`,
      },
    },
    [theme.breakpoints.down(1200)]: {
      height: !isoption && ismq && "100%",
    },
  })
);

const IndicationMainContainer = styled(Box)(({ theme, img }) => ({
  marginTop: img === "true" && "2rem",
}));

const IndicationBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.7rem",
  fontSize: ".875rem",
}));

const KvaIndicationText = styled("p")(({ theme }) => ({
  fontStyle: "italic",
  marginBottom: "1rem",
  minWidth: "fit-content",
}));

const QuestionNumberText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const DescriptionTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "18px",
  fontWeight: "600",
}));

const DescriptionText = styled(MarkLatex)(({ theme, darkmode, elf, type }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: type === "LÄS" || type === "ELF" ? "Noto Sans" : "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: elf ? 400 : 600,
  lineHeight: "20px",
  maxWidth: "100%",
  marginBottom: "1rem",
  "& img": {
    maxWidth: elf ? "141" : "100%",
    minHeight: elf && "127px",
    objectFit: !elf && "contain",
    filter: darkmode && "invert(0.9)",
    minWidth: "300px",
    maxHeight: "400px",
  },
  "& p": {
    maxWidth: "100%",
    overflowX: "auto",
    whiteSpace: "normal",
    wordWrap: "normal",
    wordBreak: "normal",
    overflowWrap: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    scrollbarWidth: "thin",
  },
  "& .math": {
    overflow: "hidden",
  },
  "& p>.math": {
    overflow: "hidden",
  },
  "& blockquote": {
    color: theme.palette.appcolors.grey,
    borderLeft: `2px solid ${theme.palette.appcolors.grey}`,
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
    lineHeight: "25px",
  },
}));

const QuestionStatement = ({
  numberOfQuestions,
  title,
  description,
  descriptionClassName,
  image,
  indications,
  type,
  key,
  width,
  iself,
  isMQ,
  isMQQuestion,
  isOptionText,
  isMQReview,
  answeropen,
}) => {
  const { darkmode } = usePersistedStore();
  const { pathname } = useLocation();

  const columnCountMemo = useMemo(() => {
    if (
      type === "DTK" ||
      (description && description.length < 2000) ||
      width < 800 ||
      pathname === "/lecture"
    ) {
      return "1";
    } else {
      return "2";
    }
  }, [type, description, width]);

  return (
    <QuestionStatementContainer
      isoption={isOptionText}
      ismq={isMQ}
      ismqreview={isMQReview}
      answeropen={answeropen}
    >
      {numberOfQuestions && (
        <QuestionNumberText>
          {numberOfQuestions &&
            `${numberOfQuestions} uppgift${numberOfQuestions > 1 ? "er" : ""}:`}
        </QuestionNumberText>
      )}
      {title && <DescriptionTitle>{title}</DescriptionTitle>}
      {descriptionClassName ? (
        <DescriptionText
          type={type}
          darkmode={darkmode}
          content={formatText(description)}
        />
      ) : (
        <Typography
          variant="subtitle1"
          elf={iself}
          style={{
            fontWeight: type === "ORD" ? 600 : type === "MEK" ? 600 : 400,
            width: image ? "100%" : "auto",
            marginTop: "1.2em",
            columnCount: columnCountMemo,
          }}
        >
          <MarkLatex
            sx={{
              "& img": {
                maxHeight: !isMQ && "400px",
                minHeight: isMQQuestion && "0",
              },
            }}
            key={key}
            content={formatText(description)}
          />
        </Typography>
      )}
      {image && (
        <img src={image ? "true" : "false"} style={{ width: "100%" }} alt="" />
      )}

      {indications && indications[0] && (
        <IndicationMainContainer img={image ? "true" : "false"}>
          {indications && indications[0] && (
            <IndicationBox mb={1}>
              {type === "KVA" ? (
                <KvaIndicationText>Kvantitet I: </KvaIndicationText>
              ) : (
                "(1)"
              )}
              <MarkLatex content={indications[0]} />
            </IndicationBox>
          )}
          {indications && indications[1] && (
            <IndicationBox>
              {type === "KVA" ? (
                <KvaIndicationText>Kvantitet II: </KvaIndicationText>
              ) : (
                "(2)"
              )}
              <MarkLatex content={indications[1]} />
            </IndicationBox>
          )}
        </IndicationMainContainer>
      )}
    </QuestionStatementContainer>
  );
};

export default QuestionStatement;
