/** Libraries */
import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/sv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";

/** Molecule */
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";
import { SecondaryIsPremiumCard } from "../../../molecule/IsPremiumCard/IsPremiumCard";

/** Atoms */
import { RightArrowIcon } from "../../../atom/CustomIcons/ArrowIcons";

/** Hooks */
import { useMixpanel } from "../../../../hooks";
import { setCustomExam } from "../../../../redux/slices";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "10px",
  marginBottom: "2rem",
  [theme.breakpoints.up(1280)]: {
    minWidth: "433px",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  maxHeight: "680px",
  borderRadius: "10px",
  border: `2px solid ${theme.palette.appcolors.grey3}`,
  [theme.breakpoints.down(600)]: {
    maxHeight: "520px",
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  "& .MuiTableCell-root": {
    padding: "0.75rem 0rem",
    border: "none",
    width: "25%",
  },
}));

const StyledTableBody = styled(TableBody)(({ theme, resulthistory }) => ({
  "& .MuiTableCell-root": {
    padding: "1rem 0rem 0.75rem",
    border: "none",
    borderBottom:
      resulthistory && resulthistory.length >= 1
        ? `1px solid ${theme.palette.appcolors.grey3}`
        : "none",
    width: resulthistory && resulthistory.length >= 1 ? "" : "33%",
    [theme.breakpoints.down(1280)]: {
      padding: "1.25rem 0rem 1rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "1rem 0rem 0.75rem",
    },
  },
}));

const PremiumCardBox = styled("div")(({ theme }) => ({
  marginBottom: "1rem",
  [theme.breakpoints.down(1280)]: {
    display: "none",
  },
}));

const HistorikTitle = styled("h3")(({ theme }) => ({
  fontSize: "28px",
  paddingBottom: "0.5rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.up(1280)]: {
    paddingTop: "0.9rem",
  },
  [theme.breakpoints.up(2400)]: {
    paddingTop: "2.4rem",
  },
}));

const TableTitleText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Figtree",
  fontWeight: "700",
  color: theme.palette.appcolors.primaryColor2,
  [theme.breakpoints.down(600)]: {
    fontSize: "11px",
  },
}));

const TableText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.appcolors.grey,
  [theme.breakpoints.down(600)]: {
    fontSize: "10px",
  },
}));

const RightBar = (props) => {
  const [resultHistory, setResultHistory] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useSelector((state) => state.user);
  const isPremium = user.isPremium;
  const { width, height } = useWindowDimensions();
  const theme = useTheme();

  useEffect(() => {
    setResultHistory(props?.data);
  }, [props?.data]);

  const ResultHandler = (row) => {
    if (row?.simuleraSeason) {
      navigate("/provresultat", {
        state: {
          quizId: row?._id,
          seasonId: row?.simuleraSeason._id,
          provpassOrder:
            props?.provpassOrderBySeason[row?.simuleraSeason?._id] ?? [],
        },
      });
    } else {
      dispatch(setCustomExam(row.customizedExam));
      navigate("/cuexresult", {
        state: {
          quizId: row?._id,
          provpassOrder: [],
        },
      });
    }
  };

  return (
    <MainContainer>
      <PremiumCardBox>
        <SecondaryIsPremiumCard isPremium={isPremium}>
          <Typography variant="h6" component="h6">
            Dina slutförda prov
          </Typography>
        </SecondaryIsPremiumCard>
      </PremiumCardBox>
      <HistorikTitle>Historik</HistorikTitle>

      <StyledTableContainer style={{ position: "relative" }}>
        <TableContainer
          style={{
            maxHeight: width > 600 ? "40rem" : height - 420,
            marginBottom: "1rem",
            padding:
              width > 1280 || width < 600 ? "0rem 1.5rem" : "0rem 2.5rem",
            overflowX: "hidden",
            overflowY:
              resultHistory && resultHistory.length >= 1 ? "scroll" : "hidden",
            scrollbarWidth: "thin",
            scrollbarColor: `${theme.palette.appcolors.grey7} ${theme.palette.appcolors.grey3}`,
          }}
        >
          <Table aria-label="simple table" style={{ padding: "2rem" }}>
            <StyledTableHead>
              <TableRow
                style={{
                  position: "absolute",
                  top: 0,
                  left: "-10%",
                  right: "-10%",
                  backgroundColor: theme.palette.appcolors.primaryColor4,
                  zIndex: -1,
                  height: "50px",
                }}
              />
              <TableRow>
                <TableCell>
                  <TableTitleText>Datum</TableTitleText>
                </TableCell>
                <TableCell align="center">
                  <TableTitleText>Prov</TableTitleText>
                </TableCell>
                <TableCell align="center">
                  <TableTitleText>Resultat</TableTitleText>
                </TableCell>
                <TableCell align="center">
                  <TableTitleText>Normering</TableTitleText>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody resulthistory={resultHistory}>
              {resultHistory && resultHistory.length >= 1 ? (
                resultHistory &&
                resultHistory?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TableText>
                        {moment(row?.updatedAt).fromNow(false)}
                      </TableText>
                    </TableCell>
                    <TableCell align="center">
                      {row?.simuleraSeason ? (
                        <TableText>
                          {row?.simuleraSeason?.month
                            .substring(0, 3)
                            .toUpperCase()}{" "}
                          {row?.simuleraSeason?.title.substring(
                            row?.simuleraSeason?.title.length - 2
                          )}
                        </TableText>
                      ) : (
                        <TableText>{row?.customizedExam?.name}</TableText>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <TableText>
                        {row?.totalAnswer ? row?.totalAnswer : 0} av{" "}
                        {row?.totalQuestions ? row?.totalQuestions : 0}
                      </TableText>
                    </TableCell>
                    <TableCell align="center">
                      <TableText>
                        {row?.normering?.toFixed(2).replace(/\.0+$/, "")}
                      </TableText>
                    </TableCell>
                    <TableCell
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        padding: "0px",
                      }}
                    >
                      <RightArrowIcon
                        style={{ fontSize: "20px" }}
                        onClick={() => {
                          mixpanel.historyTableUsed("Exams");
                          ResultHandler(row);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </StyledTableContainer>
    </MainContainer>
  );
};

export default RightBar;
