import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledAnswerText = styled(Box)(
  ({ theme, amazonpic, oplength, emptyimage }) => ({
    alignItems: "center",
    display: amazonpic != "true" && "flex",
    marginLeft: oplength === "true" || emptyimage === "true" ? "1rem" : "0",
    maxWidth: amazonpic === "true" ? "100%" : "700px",
    overflowX: "clip",
    justifyContent: oplength === "true" || !amazonpic ? "flex-start" : "center",
  })
);
