import { styled } from "@mui/material/styles";

const MainDialogContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "3rem",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(800)]: {
    padding: "2rem",
    paddingTop: "3rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    paddingTop: "3rem",
  },
}));

const DialogTitleFont = styled("h5")(({ theme, mt }) => ({
  fontSize: "18px",
  marginTop: mt && "1rem",
  fontWeight: 600,
}));

const FatDialogFont = styled("span")(({ theme }) => ({
  fontWeight: 600,
}));

export const GuaranteePopup = () => (
  <MainDialogContainer>
    <DialogTitleFont>Upptäck vår Resultatgaranti!</DialogTitleFont>
    <p>
      Känner du osäkerhet inför högskoleprovet och undrar om du kommer att nå
      ditt mål? Med HP-appen kan du känna dig tryggare med vår unika{" "}
      <FatDialogFont>Resultatgaranti!</FatDialogFont>
    </p>
    <DialogTitleFont mt>Vad är Resultatgaranti?</DialogTitleFont>
    <p>
      HP-appens Resultatgaranti är unik. Vi garanterar inte bara att du
      förbättrar ditt resultat, utan också att du{" "}
      <FatDialogFont>når ditt mål</FatDialogFont> på högskoleprovet – annars får
      du pengarna tillbaka! Vi återbetalar då hela kostnaden upp till 1500kr för
      HP-appen Premium.
    </p>
    <DialogTitleFont mt>Varför välja vår resultatgaranti?</DialogTitleFont>
    <p>
      Vi tror på dig och att du, med rätt verktyg och hårt arbete, kommer att
      lyckas på provet. Så här fungerar det:
    </p>
    <ol>
      <li>
        <p>
          <FatDialogFont>Studera med HP-appen Premium:</FatDialogFont> Använd
          vårt skräddarsydda material och tusentals uppgifter med lösningar för
          att förbereda dig.
        </p>
      </li>
      <li>
        <p>
          <FatDialogFont>Bevisade resultat:</FatDialogFont> Vi har hjälpt många
          studenter att nå sina drömmars utbildning genom högskoleprovet.
        </p>
      </li>
      <li>
        <p>
          <FatDialogFont>Pengarna tillbaka:</FatDialogFont> Om du inte når ditt
          mål, får du hela premiumkostnaden tillbaka, förutsatt att du uppfyller
          kriterierna.
        </p>
      </li>
      <li>
        <p>
          <FatDialogFont>Fullständig support:</FatDialogFont> Kontakta oss på
          magnus@hpappen.se så behandlar vi ditt ärende snabbt.
        </p>
      </li>
    </ol>

    <DialogTitleFont>Studiekriterier för Resultatgarantin:</DialogTitleFont>
    <p>
      För att aktivera garantin behöver du utnyttja HP-appens studiematerial
      innan provdagen:
    </p>
    <ul>
      <li>
        <p>
          Genomför minst 80% av HP-appens lektioner (även kallat
          studiematerial).
        </p>
      </li>
      <li>
        <p>
          Korrekt besvara minst 80% av HP-appens övningar inom XYZ, KVA, NOG,
          DTK, ELF, LÄS och MEK. Du kan repetera frågor tills du svarar korrekt.
        </p>
      </li>
      <li>
        <p>Korrekt besvara minst 40% av HP-appens ORD-övningar.</p>
      </li>
    </ul>
    <DialogTitleFont>Hur du utnyttjar Resultatgarantin:</DialogTitleFont>
    <ol>
      <li>
        <p>Uppfyll studiekriterierna.</p>
      </li>
      <li>
        <p>Genomför provet och kontrollera ditt resultat.</p>
      </li>
      <li>
        <p>
          Om du inte uppnådde ditt mål, anmäl dig till nästa prov och skicka
          anmälningsbeviset till oss på magnus@hpappen.se.
        </p>
      </li>
      <li>
        <p>
          Vi återbetalar ditt premiumköp efter kontroll att alla villkor
          uppfyllts (inom 1 vecka).
        </p>
      </li>
      <li>
        <p>
          Resultatgarantin kan endast utnyttjas för ditt resultat på senaste
          högskoleprovet.
        </p>
      </li>
    </ol>
    <DialogTitleFont>Börja din resa idag!</DialogTitleFont>
    <p>
      Skaffa Premium idag och påbörja din resa mot framgång på högskoleprovet.
      Med vår Resultatgaranti kan du känna dig trygg med att vi backar upp dig
      hela vägen mot ditt mål.
    </p>
  </MainDialogContainer>
);
