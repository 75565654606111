import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledAnswerOptions = styled(Box)(
  ({ theme, expanded, selected }) => ({
    minHeight: expanded === "true" ? "82px" : "150px",
    maxHeight: expanded === "true" ? "100%" : "150px",
    maxWidth: expanded === "true" ? "100%" : "50%",
    width: expanded === "true" ? "100%" : "49%",
    display: "flex",
    alignItems: expanded === "true" ? "center" : "flex-start",
    justifyContent: expanded === "true" ? "" : "flex-start",
    gap: expanded === "true" ? "32px" : "0px",
    alignSelf: "stretch",
    padding: expanded === "true" ? "24px 15px" : 0,
    borderRadius: "16px",
    backgroundColor: theme.palette.appcolors.secondaryColor4,
    border: selected
      ? `2px solid ${theme.palette.appcolors.primaryColor}; border-bottom: 4px solid ${theme.palette.appcolors.primaryColor};`
      : `1px solid ${theme.palette.appcolors.grey4}; border-bottom: 3px solid ${theme.palette.appcolors.grey4};`,
    color: selected
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.grey,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.appcolors.primaryColor,
      border:
        !selected &&
        `1px solid ${theme.palette.appcolors.primaryColor}; border-bottom: 3px solid ${theme.palette.appcolors.primaryColor};`,
      transform: "scaleX(1.02)",
      transformOrigin: "0% 50%",
      transition: "transform 0.2s ease-in-out",
    },
    [theme.breakpoints.down(600)]: {
      minHeight: expanded === "true" ? "82px" : "143px",
      maxHeight: "100%",
      maxWidth: "100%",
      width: "100%",
      padding: "24px 15px 24px 5px",
    },
  })
);
