/** Libraries */
import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

/** Assets */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";
import Prices from "../../../assets/Static/Prices";

/** Organisms */
import PayButton from "./PayButton";
import {
  PartialOneMonthPremiumList,
  OneMonthPremiumList,
  SixMonthPremiumList,
} from "./AlternativeListValues";

/** Styles */
import {
  PlanBox,
  PopularityTab,
  HorizontalLine,
  DiscountLabel,
  PayButtonContainer,
  PriceContainer,
  PriceText,
  ManadText,
  MonthsTitle,
  PriceSubtitle,
  PaySectionContainer,
  ListContainer,
  RadioContainer,
  FullPriceLabel,
  FullPlanSwitchContainer,
  PlanSubtitle,
  ActivePlanTitle,
  PriceTextContainer,
  ActiveTab,
  ActiveLabelText,
  PaymentTermText,
} from "../PaymentOrg/PricingPlans.styles";

/*  Hooks */
import { useDiscountStore, useMixpanel } from "../../../hooks";
import { useRef } from "react";
import CheckoutPopup from "../../molecule/CheckoutPopup/CheckoutPopup";
import { useSubsPopups } from "../../../hooks/utils/useSubsPopups";

const PricingPlan = ({
  defaultPlan,
  isHalfYearly,
  initiatePayment,
  price,
  amount_off,
  goPayment,
  disablePaymentButton,
}) => {
  const mixpanel = useMixpanel();
  const plans = Object.values(Prices);
  const { planId } = useSubsPopups();
  const { isCodeValid, discount: discountObject } = useDiscountStore();
  const { amount_off: discount } = discountObject;
  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const paymentButtonRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const [sectionToBuy, setSectionToBuy] = React.useState(
    user?.previousPlan ? user?.previousPlan : "kvantitativ"
  );
  const isPremium = user.isPremium;

  const isPartialPlan = useMemo(() => {
    if (user?.areVerbalFeaturesEnabled && !user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    if (!user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    return false;
  }, []);

  let currentPlan = user.currentPlan ? user.currentPlan : null;

  if (!currentPlan) {
    const isOneMonthPlan =
      user?.premiumExpiryDate && user?.premiumDate
        ? new Date(user.premiumExpiryDate) >
          new Date(
            new Date(user.premiumDate).getTime() + 33 * 24 * 60 * 60 * 1000
          )
        : false;

    if (isPremium) {
      if (isPartialPlan) {
        currentPlan = "planOne";
      } else {
        if (user?.premiumExpiryDate || user?.isPremium) {
          if (
            isOneMonthPlan ||
            (user?.isPremium && user?.premiumExpiryDate === null)
          ) {
            currentPlan = "planThree";
          } else {
            currentPlan = "planTwo";
          }
        }
      }
    }
  }

  useEffect(() => {
    if (isPartialPlan) {
      if (user?.areVerbalFeaturesEnabled) {
        setSectionToBuy("verbal");
      } else {
        setSectionToBuy("kvantitativ");
      }
    }
  }, [isPartialPlan]);

  useEffect(() => {
    if (initiatePayment && selectedPlan === "planThree") {
      paymentButtonRef?.current?.click();
    }
  }, [sectionToBuy, selectedPlan, initiatePayment]);

  const displayedPlans = plans;

  const discountPrices = useMemo(() => {
    // Deep copy Prices and apply discount
    const copiedPrices = JSON.parse(JSON.stringify(Prices));

    Object.keys(copiedPrices).forEach((planKey) => {
      const plan = copiedPrices[planKey];
      if (discount) {
        plan.price -= discount;
        plan.pricePerMonth =
          plan.price % plan.premiumLength < 0.5
            ? Math.floor(plan.price / plan.premiumLength)
            : (plan.pricePerMonth = Math.ceil(plan.price / plan.premiumLength));
      }
    });

    return Object.values(copiedPrices);
  }, [discount]);

  const returnCurrentPlan = (plan, index) => {
    return (
      // isPremium && plan?.id === currentPlan ? (
      //   // <MonthsTitle gratistitle>Nuvarande Plan</MonthsTitle>
      //   <CancelSubscriptionButton
      //     onClick={cancelSubscription}
      //     disabled={user.orderStatus === "canceled"}
      //   >
      //     {" "}
      //     Avsluta abonnemang{" "}
      //   </CancelSubscriptionButton>
      // ) : (
      <PayButtonContainer>
        <PayButton
          setSelectedPlan={setSelectedPlan}
          sectionToBuy={sectionToBuy}
          price={price}
          paymentButtonRef={plan?.id === "planThree" ? paymentButtonRef : null}
          goPayment={(planDetails, sectionToBuy) =>
            goPayment(planDetails, sectionToBuy)
          }
          planId={plan?.id}
          planDetails={() => {
            const planWithoutDiscount = isPartialPlan
              ? Prices["payingFullFromPartial"]
              : plan;
            if (discount) {
              return discountPrices.find(
                (discountPlan) => discountPlan.id === plan?.id
              );
            } else {
              return planWithoutDiscount;
            }
          }}
          isDisabled={isPremium && currentPlan === "planThree"}
          isCancelButton={isPremium && plan?.id === currentPlan}
          disablePaymentButton={
            disablePaymentButton ||
            (index === 2 && isPremium && user.orderStatus !== "canceled")
          }
        />
      </PayButtonContainer>
    );
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    mixpanel.premiumPlanClicked(planId);
  };

  const returnSubtitle = (plan) => {
    if (plan?.id === "planOne") {
      return "Verbal eller kvantitativ del";
    } else if (plan?.id === "planTwo") {
      return "Hela HP-appen";
    } else {
      return "Hela HP-appen i 6 månader";
    }
  };

  const handleChange = (event) => {
    if (!isPremium) return setSectionToBuy(event.target.value);
    if (!user?.previousPlan) {
      setSectionToBuy(event.target.value);
    }
  };

  const {
    openCheckoutPopup,
    popupConfig,
    handleOpenCheckoutPopup,
    handleCloseCheckoutPopup,
    handleConfirm,
  } = useSubsPopups();

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{ justifyContent: "center", alignContent: "center", margin: "auto" }}
    >
      {(isPremium && user.currentPlan !== "planThree"
        ? displayedPlans.slice(0, 2)
        : displayedPlans.slice(0, 3)
      ).map((plan, index) => (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={12}
          md={12}
          lg={4}
          key={index}
          sx={{
            display: plan?.id === "payingFullFromPartial" ? "none" : "block",
            paddingBottom: "2rem",
          }}
        >
          <CheckoutPopup
            goPayment={(planDetails, sectionToBuy) =>
              goPayment(planDetails, sectionToBuy)
            }
            plan={plan}
            sectionToBuy={sectionToBuy}
            isOpened={plan?.id === planId}
            isCancelButton={
              isPremium && plan?.id === currentPlan && plan?.id !== "planThree"
            }
          />
          <PlanBox
            key={index}
            planid={plan.id}
            width={width}
            // onClick={() => handleSelectPlan(plan?.id)}
          >
            {isPremium &&
              plan?.id === currentPlan &&
              plan?.id !== "planThree" && (
                <ActiveTab planid={plan?.id}>
                  <ActiveLabelText>Active Plan</ActiveLabelText>
                </ActiveTab>
              )}
            {plan?.popular && (
              <PopularityTab planid={plan?.id}>Mest populär</PopularityTab>
            )}
            <MonthsTitle
              isactivetab={
                isPremium &&
                plan?.id === currentPlan &&
                plan?.id !== "planThree"
              }
              planid={plan?.id}
            >
              {plan?.name}
            </MonthsTitle>
            <HorizontalLine planid={plan?.id} />
            <PlanSubtitle planid={plan?.id}>
              {returnSubtitle(plan)}
            </PlanSubtitle>

            <PriceContainer>
              <PriceTextContainer>
                <PriceText>
                  {/* {plan?.id !== "planOne"
                    ? plan?.price - amount_off / 100
                    : plan?.price}{" "}
                  SEK */}
                  {isHalfYearly
                    ? plan?.price?.halfYearly
                    : plan?.price?.monthly}{" "}
                  kr
                </PriceText>
                {plan?.id === "planThree" && (
                  <DiscountLabel toptext>Spara 40%</DiscountLabel>
                )}
              </PriceTextContainer>

              {plan?.id !== "planThree" && <ManadText>/månad</ManadText>}
            </PriceContainer>
            <PaySectionContainer>
              {returnCurrentPlan(plan, index)}
              {plan?.id === "planOne" && (
                <>
                  <RadioContainer>
                    <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={sectionToBuy}
                        onChange={handleChange}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "nowrap",
                          marginLeft: "1rem",
                          gap: "2rem",
                          "& .MuiButtonBase-root": {
                            paddingRight: "6px",
                            color: theme.palette.appcolors.primaryColor,
                          },
                          "& .MuiRadio-root.Mui-checked": {
                            color: theme.palette.appcolors.primaryColor,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="kvantitativ"
                          control={<Radio size="small" />}
                          label="Kvantitativ"
                          disabled={
                            isPremium &&
                            ["verbal", "kvantitativ"].includes(
                              user.previousPlan
                            )
                          }
                        />
                        <FormControlLabel
                          value="verbal"
                          disabled={
                            isPremium &&
                            ["verbal", "kvantitativ"].includes(
                              user.previousPlan
                            )
                          }
                          control={<Radio size="small" />}
                          label="Verbal"
                        />
                      </RadioGroup>
                    </FormControl>
                  </RadioContainer>
                  <PriceSubtitle toptext>Välj del</PriceSubtitle>
                </>
              )}
            </PaySectionContainer>
            <ListContainer>
              {plan?.id === "planOne" ? (
                <PartialOneMonthPremiumList />
              ) : plan?.id === "planTwo" ? (
                <OneMonthPremiumList />
              ) : (
                <SixMonthPremiumList />
              )}
            </ListContainer>
          </PlanBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingPlan;
