/** Libraries */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Assets */
import LikeIcon from "../../../assets/Icons/LikeCircleIcon.svg";
import UnLikeIcon from "../../../assets/Icons/UnLikeCircleIcon.svg";

/** Utils */
import { appColors } from "../../../utils/commonService";
import { useFeedbackQuestionsStore } from "../../../hooks";

/** Material UI - Custom elements */
const FeedbackButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "12px",
  paddingBottom: "4px",
  width: "100%",
  gap: "12px",
  marginBottom: "5px",
}));

const TextTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.grey,
  textAlign: "right",
  fontFamily: "Noto Sans",
  fontSize: "12px",
  [theme.breakpoints.down(460)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(415)]: {
    fontSize: "9px",
  },
}));

const IconContainer = styled(Box)(({ theme, red }) => ({
  display: "flex",
  width: "26px",
  height: "26px",
  minWidth: "26px",
  minHeight: "26px",
  paddingBottom: "0px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "13px",
  backgroundColor:
    red === "true"
      ? theme.palette.appcolors.error6
      : theme.palette.appcolors.success4,
  paddingTop: red === "true" && "2px",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1)",
    transition: "all 0.2s ease-in-out",
  },
}));

const Icon = styled("img")(({ theme }) => ({
  fill: theme.palette.appcolors.secondaryColor10,
  width: "14.991px",
  height: "15.352px",
  flexShrink: 0,
}));

const FeedbackButtons = () => {
  const { startPlusPoint, startMinusPoint } = useFeedbackQuestionsStore();
  return (
    <FeedbackButtonsContainer>
      <TextTitle>Berätta för oss om du var nöjd med lösningen</TextTitle>
      <IconContainer
        red={"false"}
        onClick={(e) => startPlusPoint(e.currentTarget)}
      >
        <Icon src={LikeIcon} alt="" />
      </IconContainer>
      <IconContainer
        red={"true"}
        onClick={(e) => startMinusPoint(e.currentTarget)}
      >
        <Icon src={UnLikeIcon} alt="" />
      </IconContainer>
    </FeedbackButtonsContainer>
  );
};

export default FeedbackButtons;
