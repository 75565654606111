import moment from "moment";

// this is a test

export const datesGroupByComponent = (dates, token) => {
  return dates.reduce(function (val, obj) {
    let comp = moment(obj["createdAt"], "YYYY/MM/DD").format(token);
    const year = new Date(obj["createdAt"]).getYear();
    (val["V." + year + "." + comp] = val["V." + year + "." + comp] || []).push(
      obj
    );
    return val;
  }, {});
};

export const compareVersions = (version1, version2) => {
  const num1 = parseInt(version1.slice(1)); // Remove 'V' and parse as integer
  const num2 = parseInt(version2.slice(1)); // Remove 'V' and parse as integer

  if (num1 > num2) {
    return true;
  } else {
    return false;
  }
};

export const appColors = {
  /** GRAYSCALE */
  blackColor: "#252525",
  grey80: "#333333",
  grey70: "#525252",
  grey60: "#6E6E6E",
  grey35: "#A6A6A6",
  grey25: "#C2C2C2",
  grey10: "#E1E1E1",
  grey6: "#F5F5F5",
  grey2: "#FAFAFA",
  whiteColor: "#FFFFFF",

  /** BLUE COLORS */
  blueColor: "#1B51DA",
  brandBlue65: "#517BE7",
  brandBlue45: "#83A2EF",
  brandBlue10: "#E8EFFF",
  brandBlue5: "#EDF3F8",

  /** ORANGE COLORS */
  Cantaloupe35: "#FFE5BF",

  /** RED COLORS */
  valentine_red: "#EB5757",

  /** GREEN COLORS */
  darkMintGreen: "#1BCF66",
  darkMintGreen45: "#6FCF97",
};

// To use this, set the fontweight to the desired fontstyle
export const poppinsFontStyle = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const setInitialUserState = (userData) => {
  const premiumExpiryDate = userData.user.premiumExpiryDate
    ? userData.user.premiumExpiryDate
    : new Date();

  localStorage.setItem("premiumExpiryDate", premiumExpiryDate);

  const currentDate = moment(new Date()).format("YYYY-MM-DD");

  const premiumExpiryDateFormat =
    moment(premiumExpiryDate).format("YYYY-MM-DD");

  const isGreaterCurrentData = premiumExpiryDateFormat >= currentDate;
};

export const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, 200);
};

export const optionsCharacters = (index) => {
  switch (index) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    case 4:
      return "E";
    default:
      return "";
  }
};

export const dispSecondsAsMins = (seconds) => {
  // 25:00
  const mins = Math.floor(seconds / 60);
  const seconds_ = seconds % 60;
  return (
    (mins < 10 ? "0" + mins : mins) +
    ":" +
    (seconds_ < 10 ? "0" + seconds_ : seconds_)
  );
};
