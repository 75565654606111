import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainContainer = styled(Box)(({ theme, yscroll, ord }) => ({
  display: "flex",
  width: "100%",
  padding: "3rem",
  height: "auto",
  minHeight: ord === "true" ? "200px" : "284px",
  flexDirection: "column",
  alignItems: "flex-start",
  maxHeight: yscroll === "true" ? "60vh" : "auto",
  overflowY: yscroll === "true" ? "auto" : "visible",
  justifyContent: yscroll === "true" ? "flex-start" : "center",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "16px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  boxSizing: "border-box",
  [theme.breakpoints.down(800)]: {
    padding: ord !== "true" && "2rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: ord !== "true" && "1rem",
    paddingTop: "2rem",
  },
  "& p, & h3": {
    textAlign: "start",
  },
  "& blockquote": {
    color: theme.palette.appcolors.grey,
    borderLeft: `2px solid ${theme.palette.appcolors.grey}`,
  },
}));

const QuestionWrapper = ({ children, questionTypeTitle, ...props }) => {
  return (
    <MainContainer
      // {...props}
      yscroll={props.yScrollEnabled ? "true" : "false"}
      ord={props?.ord ? "true" : "false"}
    >
      {/*  Here we should use QuestionStatement as a children*/}
      {children}
    </MainContainer>
  );
};

export default QuestionWrapper;
