import { useDispatch, useSelector } from "react-redux";
import { SendSentryError } from "../../tools";
import { EndPoints, instance2 } from "../../components/service/Route";
import { update } from "../../redux/slices";

export const usePremiumPlans = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const cancelSubscription = () => {
    const url = EndPoints.cancelSubscription;
    instance2
      .post(url)
      .then((res) => {
        if (res.data.message == "No Active subscriptions") {
          SendSentryError("CANCEL", res.data.message, {
            details: `User Detail: name${user?.fullName}.`,
          });
        } else if (res.data.message) {
          SendSentryError("CANCEL", "Subscription canceled Successfully!", {
            details: `Subscription canceled Successfully!!
            User Detail: name${user?.fullName}.`,
          });
        } else {
          SendSentryError("CANCEL", "Subscription not canceled!", {
            details: `Subscription not canceled!!
            User Detail: name${user?.fullName}.`,
          });
        }
        dispatch(
          update({
            subscriptionId: "",
            orderStatus: "canceled",
          })
        );
        // navigate("/home");
      })
      .catch((err) => {
        SendSentryError("CANCEL", "Subscription not canceled!", {
          details: `Subscription not canceled!!
          User Detail: name${user?.fullName}.`,
        });
      });
  };
  return {
    cancelSubscription,
  };
};
