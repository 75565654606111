/** Libraries */
import { styled } from "@mui/material/styles";

/** Hooks */
import { useMixpanel } from "../../../hooks";
import { useSubsPopups } from "../../../hooks/utils/useSubsPopups";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

const StyledPayButton = styled(Button)(({ theme, disabled }) => ({
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  backgroundColor: `${
    disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor
  } !important`,
  borderBottom: `3px solid ${
    disabled ? theme.palette.appcolors.grey3 : theme.palette.appcolors.black3
  } !important`,
  borderRadius: "16px !important",
  padding: "8px 16px !important",
  minWidth: "200px !important",
  transition: "all 0s ease-in-out",
  fontSize: "18px !important",
  fontWeight: "600 !important",
  textTransform: "none !important",
  "&:hover": {
    backgroundColor: disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor,
    transform: "scale(1.05)",
    transition: "all 0.3s ease-in-out",
  },
  [theme.breakpoints.down(1280)]: {
    width: "90% !important",
  },
}));

const StyledCancelButton = styled(Button)(({ theme, disabled }) => ({
  minWidth: "200px !important",
  borderRadius: "16px !important",

  borderTop: `1.02px solid ${
    disabled
      ? theme.palette.appcolors.secondaryColor
      : theme.palette.appcolors.primaryColor
  }`,
  borderRight: `1.02px solid ${
    disabled
      ? theme.palette.appcolors.black3
      : theme.palette.appcolors.primaryColor
  }`,
  borderBottom: `3.06px solid ${
    disabled
      ? theme.palette.appcolors.black3
      : theme.palette.appcolors.primaryColor
  }`,
  borderLeft: `1.02px solid ${
    disabled
      ? theme.palette.appcolors.black3
      : theme.palette.appcolors.primaryColor
  }`,
  padding: "8.16px 16.32px !important",

  color: `${
    disabled
      ? theme.palette.appcolors.secondaryColor
      : theme.palette.appcolors.primaryColor
  } !important`,
  backgroundColor: `${
    disabled ? theme.palette.appcolors.grey3 : "transparent"
  } !important`,

  transition: "all 0s ease-in-out",
  fontSize: "18px !important",
  fontWeight: "600 !important",
  textTransform: "none !important",

  "&:hover": {
    backgroundColor: disabled ? theme.palette.appcolors.grey3 : "transparent",
    transform: "scale(1.05)",
    transition: "all 0.3s ease-in-out",
  },

  [theme.breakpoints.down(1280)]: {
    width: "90% !important",
  },
}));

const PayButton = ({
  isCancelButton,
  isDisabled,
  goPayment,
  disablePaymentButton,
  planDetails,
  planId,
  sectionToBuy,
  setSelectedPlan,
}) => {
  const mixpanel = useMixpanel();
  const { handleOpenCheckoutPopup } = useSubsPopups();
  const { user } = useSelector((state) => state.user);
  const isPremium = user?.isPremium;

  const handleGoPayment = async () => {
    if (!disablePaymentButton) {
      const plan = await planDetails();
      setSelectedPlan(plan?.id);
      if (!isPremium) {
        goPayment(plan, sectionToBuy);
        mixpanel.buttonClicked("Upgrade");
        return;
      }
      handleOpenCheckoutPopup(planId);
    }
  };

  return isCancelButton ? (
    <StyledCancelButton
      disabled={user.orderStatus === "canceled"}
      onClick={handleGoPayment}
    >
      {" "}
      Avsluta abonnemang{" "}
    </StyledCancelButton>
  ) : (
    <StyledPayButton
      disabled={disablePaymentButton || isDisabled}
      onClick={handleGoPayment}
    >
      {planId === "planThree" ? "Köp direkt" : "Prenumerera"}
    </StyledPayButton>
  );
};

export default PayButton;
