/** Libraries */
import { styled } from "@mui/material/styles";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box } from "@mui/material";

/** Atoms */
import { StarIcon } from "../../../../components/atom/CustomIcons/StarIcon";

/** Material UI - Custom elements */
export const StyledSparaButtonUnsaved = styled(BookmarkBorderRoundedIcon)(
  ({ theme }) => ({
    position: "absolute !important",
    top: "1rem",
    right: "1rem",
    color: theme.palette.appcolors.grey7,
    fontSize: "26px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.appcolors.primaryColor,
      transform: "scale(1.1)",
      transition: "all 0.3s ease",
    },
  })
);

export const StyledSparaButtonSaved = styled(BookmarkRoundedIcon)(
  ({ theme }) => ({
    position: "absolute !important",
    top: "1rem",
    right: "1rem",
    color: theme.palette.appcolors.primaryColor,
    fontSize: "26px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.appcolors.primaryColor,
      transform: "scale(1.1)",
      transition: "all 0.3s ease",
    },
  })
);

export const StyledMarkeraContainer = styled(Box)(({ theme, marked }) => ({
  position: "absolute !important",
  top: "1rem",
  right: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "row",
  gap: marked === "true" ? "0.2rem" : "0.5rem",
  width: "109px",
  height: "37px",
  padding: "0.5rem",
  cursor: "pointer",
  border: `1px solid ${
    marked === "true"
      ? theme.palette.appcolors.tertiaryColor13
      : theme.palette.appcolors.grey7
  }`,
  borderRadius: "5px",
}));

export const StyledMarkeraButton = styled(StarIcon)(({ theme, marked }) => ({
  color:
    marked === "true"
      ? theme.palette.appcolors.tertiaryColor13
      : theme.palette.appcolors.grey7,
  fontSize: "26px",
  "&:hover": {
    color: theme.palette.appcolors.tertiaryColor13,
    transform: "scale(1.1)",
    transition: "all 0.3s ease",
  },
}));

export const StyledMarkeraText = styled("p")(({ theme, marked }) => ({
  color:
    marked === "true"
      ? theme.palette.appcolors.tertiaryColor13
      : theme.palette.appcolors.grey7,
  fontSize: "12px",
  fontWeight: "500",
}));

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.appcolors.primaryColor,
    color: theme.palette.appcolors.secondaryColor10,
    width: "158px",
    height: "55px",
    paddingLeft: "1rem",
    fontFamily: "Figtree",
    fontSize: "14px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.appcolors.primaryColor,
  },
}));
