/** Libraries */
import React, { useState } from "react";
import PropTypes from "prop-types";

/** Styles */
import {
  StyledSparaButtonUnsaved,
  StyledSparaButtonSaved,
  CustomTooltip,
  StyledMarkeraButton,
  StyledMarkeraContainer,
  StyledMarkeraText,
} from "./SparaButton.styles";

/**
 * @docs https://www.notion.so/Atomic-design-c511ecc580614e919d052ecee7b6a8e1?pvs=4#394d37040b564b0cad60ef08a8a29a17
 */
export const SparaButton = ({
  isQuestionFlagged,
  sparaSaveButtonRef,
  isExam,
  ...props
}) => {
  const [tooltip, setTooltip] = useState(false);

  return (
    <CustomTooltip
      onMouseEnter={() => !isExam && setTooltip(true)}
      onMouseLeave={() => !isExam && setTooltip(false)}
      title={`Spara frågan i filtret "Bokmärkt"`}
      placement="left"
      arrow
      open={tooltip}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      {isQuestionFlagged ? (
        isExam ? (
          <StyledMarkeraContainer
            marked={"true"}
            ref={sparaSaveButtonRef}
            {...props}
          >
            <StyledMarkeraButton marked={"true"} />
            <StyledMarkeraText marked={"true"}>Markerad</StyledMarkeraText>
          </StyledMarkeraContainer>
        ) : (
          <StyledSparaButtonSaved ref={sparaSaveButtonRef} {...props} />
        )
      ) : isExam ? (
        <StyledMarkeraContainer
          marked={"false"}
          ref={sparaSaveButtonRef}
          {...props}
        >
          <StyledMarkeraButton marked={"false"} />
          <StyledMarkeraText marked={"false"}>Markera</StyledMarkeraText>
        </StyledMarkeraContainer>
      ) : (
        <StyledSparaButtonUnsaved ref={sparaSaveButtonRef} {...props} />
      )}
    </CustomTooltip>
  );
};

SparaButton.propTypes = {
  isQuestionFlagged: PropTypes.bool,
  sparaSaveButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isExam: PropTypes.bool,
};
