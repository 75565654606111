/** Libraries */
import { Avatar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** MUI styled elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "auto",
}));

export const StyledAvatar = styled(Avatar)(
  ({ theme, index, selectedIndex }) => ({
    textAlign: "center",
    fontFamily: "Noto Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    marginTop: "21px",
    marginBottom: "21px",
    padding: "10px",
    gap: "10px",
    width: "36px",
    height: "36px",
    cursor: "pointer",
    backgroundColor:
      index === selectedIndex
        ? theme.palette.appcolors.primaryColor
        : "transparent",
    color:
      index === selectedIndex
        ? theme.palette.appcolors.primaryColor7
        : theme.palette.appcolors.grey9,
    transitionProperty: "all",
    transitionDuration: "0.1s",
    transitionTimingFunction: "ease",
  })
);
