import React from "react";
import Pricing from "./Pricing";
import { useNavigate } from "react-router-dom";
import { StyledCloseIcon } from "../../atom/CustomIcons/StyledCloseIcon";

const PaymentMain = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          zIndex: 1300,
          position: "absolute",
          top: "2rem",
          left: "2rem",
        }}
      >
        <StyledCloseIcon onClick={() => navigate("/home")} />
      </div>
      <Pricing />
    </>
  );
};

export default PaymentMain;
