import { useState } from "react";
import { Box, FormControlLabel } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

/** Atoms */
import { StyledAnswerOptions } from "../../../atom/StyledAnswerOptions/StyledAnswerOptions";
import { QuestionImage } from "../../../atom/QuestionImage/QuestionImage";
import { StyledAnswerText } from "../../../atom/StyledAnswerText/StyledAnswerText";
import { TextQuestionRow } from "../../../atom/TextQuestionRow/TextQuestionRow";

/** Utils */
import { optionsCharacters } from "../../../../utils/commonService";
import { formatText } from "../../../../tools";

const StyledAnswersContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex !important",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "0.5rem",
}));

const RowRadiusButtonFirstContainer = styled("div")(
  ({ theme, includesimage }) => ({
    position: includesimage ? "absolute" : "static",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: includesimage ? "0.5rem" : 0,
  })
);

const RowRadiusButtonSecondContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const RowRadius = styled(FormControlLabel)(({ theme }) => ({
  width: "16px",
  height: "16px",
  margin: 0,
  marginRight: "5px",
  padding: 0,
  "&.MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const RowRadiusIndexContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "45px",
  height: "32.5px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
}));

const RadiusIndexText = styled("p")(({ theme }) => ({
  fontSize: "10px",
  textTransform: "uppercase",
}));

const OptionsMQ = ({
  options,
  selectedopindex,
  SelectFunc,
  startSelectingQuestion,
  Options,
}) => {
  const theme = useTheme();
  const [onHover, setOnhover] = useState();

  const handleOptionsColor = (isHoverOption, isSelectedOption) => {
    if (isSelectedOption) {
      return theme.palette.appcolors.primaryColor;
    }

    if (isHoverOption) {
      return theme.palette.appcolors.primaryColor;
    }

    return theme.palette.appcolors.grey3;
  };

  return (
    <StyledAnswersContainer>
      {options.map((option, optionIndex) => {
        return (
          <StyledAnswerOptions
            key={option._id}
            expanded={
              options.length > 4 ||
              !option.value.includes("hp-appen.s3.eu-north-1.amazonaws.com")
                ? "true"
                : "false"
            }
            selected={optionIndex === selectedopindex}
            onClick={(e) => {
              SelectFunc && SelectFunc(option._id, optionIndex);
              startSelectingQuestion &&
                startSelectingQuestion(option, optionIndex);
            }}
            onMouseOver={() => setOnhover(option._id)}
            onMouseLeave={() => setOnhover()}
          >
            <RowRadiusButtonFirstContainer
              includesimage={option.value.includes(
                "hp-appen.s3.eu-north-1.amazonaws.com"
              )}
            >
              <RowRadiusButtonSecondContainer>
                <RowRadius
                  value={option?._id}
                  control={Options(option, optionIndex)}
                />
              </RowRadiusButtonSecondContainer>
              <RowRadiusIndexContainer>
                <RadiusIndexText
                  style={{
                    color: handleOptionsColor(
                      option._id === onHover,
                      optionIndex === selectedopindex
                    ),
                  }}
                >
                  {optionsCharacters(optionIndex)}
                </RadiusIndexText>
              </RowRadiusIndexContainer>
            </RowRadiusButtonFirstContainer>

            {option?.value && (
              <>
                {option.value.includes(
                  "hp-appen.s3.eu-north-1.amazonaws.com"
                ) ? (
                  <QuestionImage content={option?.value} />
                ) : (
                  <StyledAnswerText
                    amazonpic={
                      option.value.includes(
                        "hp-appen.s3.eu-north-1.amazonaws.com"
                      )
                        ? "true"
                        : "false"
                    }
                    oplength={options.length > 4 ? "true" : "false"}
                    emptyimage={option.image === "" ? "true" : "false"}
                  >
                    <TextQuestionRow content={formatText(option?.value)} />
                  </StyledAnswerText>
                )}
              </>
            )}
          </StyledAnswerOptions>
        );
      })}
    </StyledAnswersContainer>
  );
};

export default OptionsMQ;
