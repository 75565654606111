import { Dialog, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "680px",
  [theme.breakpoints.up(1280)]: {
    minWidth: "433px",
  },
}));

export const SmallScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [theme.breakpoints.down(1280)]: {
    display: "none",
  },
}));

export const SmallScreenAnalys = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));

export const SubContainer = styled("div")(({ theme, top }) => ({
  width: "100%",
  minHeight: "110px",
  borderRadius: 10,
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  display: "flex",
  flexDirection: !top && "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: top ? "10px 25px" : "25px",
  marginBottom: "1rem",
  "&:hover": {
    cursor: top && "pointer",
  },
}));

export const CheckmarkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.appcolors.black,
  width: "50px",
  height: "50px",
  borderRadius: 5,
  backgroundColor: theme.palette.appcolors.primaryColor10,
}));

export const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
}));

export const TitleText = styled("div")(({ theme }) => ({
  marginBottom: "10px",
  fontSize: "23px",
  fontWeight: 600,
  color: theme.palette.appcolors.black,
  [theme.breakpoints.up(2400)]: {
    marginTop: "1.2rem",
  },
}));

export const GoalText = styled("p")(({ theme, ml, mt, part }) => ({
  color: theme.palette.appcolors.black,
  marginLeft: ml && "1rem",
  marginTop: mt && "1rem",
  fontSize: part && "14px",
}));

export const ProgressTitle = styled("h3")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontWeight: 600,
  fontSize: "18px",
  marginBottom: "0rem",
}));

export const ProgressSubTitle = styled("p")(({ point, theme }) => ({
  color:
    point > 0
      ? theme.palette.appcolors.primaryColor16
      : theme.palette.appcolors.error7,
  fontSize: "10px",
  fontFamily: "Figtree",
  fontWeight: 700,
  marginTop: "-2px",
}));

export const ProgressTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderBottom: `1px solid ${theme.palette.appcolors.grey9}`,
  paddingBottom: "1rem",
}));

export const WholeTestProgressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderBottom: `1px solid ${theme.palette.appcolors.grey9}`,
  padding: "1rem 0rem",
}));

export const TitleAndProgressBar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "57%",
}));

export const PartsProgressContainer = styled("div")(({ bb }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderBottom: !bb && `1px solid ${theme.palette.appcolors.grey9}`,
  padding: bb ? "1rem 0rem 0rem 0rem" : "1rem 0rem",
}));

export const LinesChartBox = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderRadius: 10,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  padding: "1.5rem",
  [theme.breakpoints.down("md")]: {
    overflow: "visible",
  },
}));

export const WholeProgressBar = styled(LinearProgress)(
  ({ theme, part, kvant, verb }) => ({
    width: "100%",
    height: part === "true" ? "7px" : "10.5px",
    borderRadius: "10px",
    backgroundColor: theme.palette.appcolors.primaryColor17,
    margin: "3px 0px",
    "& .MuiLinearProgress-bar": {
      backgroundColor:
        kvant === "true"
          ? theme.palette.appcolors.primaryColor15
          : verb === "true"
          ? theme.palette.appcolors.tertiaryColor11
          : theme.palette.appcolors.success4,
      borderRadius: "10px",
    },
  })
);

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  margin: "auto",
  maxWidth: "1230px",
  maxHeight: "908px",
  [theme.breakpoints.down(2400)]: {
    maxWidth: "863px",
    maxHeight: "663px",
  },
  [theme.breakpoints.down(600)]: {
    maxHeight: "833px",
  },
}));

export const ColumnContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
