import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import { styled } from "@mui/material/styles";
import { Box, Rating } from "@mui/material";

/** Assets */
import profilepicnila from "../../../assets/Imgs/profilepicnila.jpg";
import pricereviewpic from "../../../assets/Imgs/pricereviewpic.png";
import pricereviewpicdark from "../../../assets/Imgs/pricereviewpicdark.png";

/** Hooks */
import { usePersistedStore } from "../../../hooks";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "2rem 0rem 1rem",
  gap: "2rem",
  width: "100%",
  [theme.breakpoints.down(800)]: {
    marginLeft: "0.5rem",
  },
}));

const MainReviewContainer = styled(Box)(({ theme, main }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "305px",
  width: "100%",
  minHeight: "87px",
  backgroundColor: theme.palette.appcolors.secondaryColor16,
  boxShadow: "0px 4px 12px 0px #00000014",
  borderRadius: "16px",
  padding: "1rem",
  gap: "0.5rem",
  [theme.breakpoints.down(1080)]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down(800)]: {
    display: !main && "none",
    maxWidth: "449px",
  },
}));

const MainReviewContainerTop = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

const MainReviewContainerText = styled("p")(({ theme, main }) => ({
  fontSize: "14px",
  color: main ? theme.palette.appcolors.grey : theme.palette.appcolors.black,
  [theme.breakpoints.down(800)]: {
    fontSize: !main && "12px",
    fontFamily: !main && "Figtree",
  },
  "& span": {
    fontWeight: "600",
    color: theme.palette.appcolors.primaryColor,
    [theme.breakpoints.down(1080)]: {
      fontSize: "16px",
    },
  },
}));

const PictureContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "0.3rem",
}));

const NameAndRatingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const CardProfilePicture = styled("img")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
}));

const CardReviewPictures = styled("img")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "35px",
  marginBottom: "-0.5rem",
  [theme.breakpoints.down(800)]: {
    height: "25px",
  },
}));

const CardProfileName = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "12px",
  fontWeight: "700",
  color: theme.palette.appcolors.black,
}));

const CardRating = styled(Rating)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.7rem",
  paddingRight: "1.5rem",
  color: theme.palette.appcolors.tertiaryColor11,
}));

const RatingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const RatingFont = styled("p")(({ theme }) => ({
  fontWeight: "600",
  fontSize: "18px",
  color: theme.palette.appcolors.primaryColor,
  "& span": {
    fontFamily: "Figtree",
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.appcolors.black,
  },
}));

const MobileScreenDisplay = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "0.5rem",
  margin: "0.5rem 0rem",
  [theme.breakpoints.up(800)]: {
    display: "none",
  },
}));

const RatingsIcon = styled(TrendingUpRoundedIcon)(({ theme }) => ({
  color: theme.palette.appcolors.success4,
}));

export const PricingReviewCards = () => {
  const { darkmode } = usePersistedStore();
  return (
    <MainContainer>
      <MainReviewContainer main sx={{ gap: "0.5rem !important" }}>
        <MainReviewContainerTop>
          <PictureContainer>
            <CardProfilePicture src={profilepicnila} />
            <NameAndRatingContainer>
              <CardProfileName>Nila</CardProfileName>
              <CardRating value={5} readOnly />
            </NameAndRatingContainer>
          </PictureContainer>
          <RatingContainer>
            <RatingFont>
              1.25 <span>till</span> 1.8
            </RatingFont>
            <RatingsIcon />
          </RatingContainer>
        </MainReviewContainerTop>
        <MainReviewContainerText main>
          "Ökade min poäng med 0.55"
        </MainReviewContainerText>
        <MobileScreenDisplay>
          <CardReviewPictures
            src={darkmode ? pricereviewpicdark : pricereviewpic}
          />
          <MainReviewContainerText>
            Studera effektivt som 20,000+ andra
          </MainReviewContainerText>
        </MobileScreenDisplay>
      </MainReviewContainer>
      <MainReviewContainer>
        <MainReviewContainerText>
          Studera effektivt som <span>20,000+</span> andra
        </MainReviewContainerText>
        <CardReviewPictures
          src={darkmode ? pricereviewpicdark : pricereviewpic}
        />
      </MainReviewContainer>
    </MainContainer>
  );
};
